<template>
  <div class="card mt-5">
    <div class="card-body text-center">
      <span v-if="timeLeft.days !== 0 || timeLeft.hours !== 0 || timeLeft.minutes !== 0 || timeLeft.seconds !== 0">
        <span v-if="timeLeft.days > 0"><b>{{ formatNumber(timeLeft.days) }}D </b></span>

        <span v-if="timeLeft.hours > 0"><b>{{ formatNumber(timeLeft.hours) }}h </b></span>

        <span v-if="timeLeft.minutes > 0 || timeLeft.hours > 0"><b>{{ formatNumber(timeLeft.minutes) }}min </b></span>

        <span><b>{{ formatNumber(timeLeft.seconds) }}seg</b></span>

      </span>
      <span v-else>
        <div>Formulário encerrado</div>
        <div><small>Mas você ainda pode falar com um de nossos representantes</small></div>
        <p/>
        <div>WhatsApp: <a href="https://wa.me/+5511974780207">+55 11 97478-0207</a></div>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CountdownTimer',
  data() {
    return {
      timer: null,
      endTime: new Date('2024-05-10T23:59:59').getTime(),
      timeLeft: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      }
    };
  },
  created() {
    this.updateTimer();
    this.timer = setInterval(this.updateTimer, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    updateTimer() {
      const now = Date.now();
      const difference = this.endTime - now;

      if (difference > 0) {
        this.timeLeft.days = Math.floor(difference / (1000 * 60 * 60 * 24));
        this.timeLeft.hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        this.timeLeft.minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        this.timeLeft.seconds = Math.floor((difference % (1000 * 60)) / 1000);
      } else {
        this.timeLeft.days = 0;
        this.timeLeft.hours = 0;
        this.timeLeft.minutes = 0;
        this.timeLeft.seconds = 0;
        clearInterval(this.timer);
        this.timer = null;
      }
      
      // Após calcular ou atualizar timeLeft, emitir para o pai
      this.$emit('update-time', this.timeLeft);
    },
    formatNumber(value) {
      return value.toString().padStart(2, '0');
    }
  }
};
</script>
