<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-dark py-7 py-lg-8 pt-lg-9">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="text-white">Cadastro de interesse</h1>
              <p class="text-lead text-white">
                Tem interesse em utilizar nosso Sistema de Gestão de Seguro Incêndio? Cadastre-se e receba beneficios na contratação.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <!-- Table -->
      <div class="row justify-content-center">
        <div class="col-lg-6 col-md-8">
          <div class="card bg-secondary border-0">
            <div class="card-header bg-transparent pb-5">
              <div>
                <div class="text-right">
                  <img src="img/brand/logo.png" class="col-5 mt-2 mb-0" />
                </div>
                <div>
                  <CountdownTimer @update-time="handleUpdateTime" v-if="sendsusses == 0"/>
                  <div  v-if="sendsusses == 1" class="text-center">
                    <p/>
                    <h1 class="mt-6">Obrigado!!!</h1>
                    <div class="mt-3 mb-6">
                      <div>Seus dados foram enviados com sucesso.</div>
                      <div>Em breve um representante entrará em contato.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body px-lg-5 py-lg-5" v-if="sendsusses == 0 && (timeLeft.days !== 0 || timeLeft.hours !== 0 || timeLeft.minutes !== 0 || timeLeft.seconds !== 0)">
              <form role="form" @submit.prevent="handleSubmit()">
                <h1 class="text-center">Cadastro</h1>
                <h3 class="mt-6">Dados da empresa:</h3>
                <base-input
                  alternative
                  class="mb-3"
                  prepend-icon="ni ni-shop"
                  placeholder="Digite o Nome da Imobiliária..."
                  name="empresa"
                  v-model="empresa"
                >
                </base-input>
                <validation-error :errors="apiValidationErrors.empresa" />
                <base-input
                  alternative
                  class="mb-3"
                  prepend-icon="fas fa-laptop"
                  placeholder="Nome do Sistema de administração Utilizado.."
                  name="sistema"
                  v-model="sistema"
                >
                </base-input>
                <validation-error :errors="apiValidationErrors.sistema" />

                <h3 class="mt-6">Dados para contato:</h3>
                <base-input
                  alternative
                  class="mb-3"
                  prepend-icon="fas fa-user-tie"
                  placeholder="Digite o Seu Nome..."
                  name="nome"
                  v-model="nome"
                >
                </base-input>
                <validation-error :errors="apiValidationErrors.nome" />

                <base-input
                  alternative
                  class="mb-3"
                  prepend-icon="fas fa-phone"
                  placeholder="Digite o telefone/Whatsapp"
                  name="telefone"
                  v-model="telefone"
                >
                </base-input>
                <validation-error :errors="apiValidationErrors.telefone" />
                
                <base-input
                  alternative
                  class="mb-3"
                  prepend-icon="ni ni-email-83"
                  placeholder="Digite o Email"
                  name="email"
                  v-model="email"
                >
                </base-input>
                <validation-error :errors="apiValidationErrors.email" />

                

                <div class="text-right">
                  <base-button type="primary" native-type="submit" class="my-4"
                    >Cadastrar</base-button
                  >
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import Password from "vue-password-strength-meter";
import CountdownTimer from '@/views/Components/CountdownTimer.vue';

export default {
  components: {
    ValidationError,
    Password,
    CountdownTimer,
  },
  mixins: [formMixin],
  
  data() {
    return {
      empresa: null,
      sistema: null,
      nome: null,
      telefone: null,
      email: null,
      interesse: null,
      interesseapi: null,
      origem: null,
      sendsusses: 0,


      timeLeft: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      }
    };
  },

  methods: {

    handleUpdateTime(timeLeft) {
      this.timeLeft = timeLeft;
    },

    async handleSubmit() {
      const interesse = {
          type: "Interesses",
          empresa: this.empresa,
          sistema: this.sistema,
          nome: this.nome,
          telefone: this.telefone,
          email: this.email,
          //interesse: this.interesse,
          //interesseapi: this.interesseapi,
          //origem: this.origem,
      };
      if(interesse.empresa == null){interesse.empresa="";}
      if(interesse.sistema == null){delete interesse.sistema;}
      if(interesse.nome == null){delete interesse.nome;}
      if(interesse.telefone == null){delete interesse.telefone;}
      if(interesse.email == null){delete interesse.email;}


      try {
        
        //await this.$store.dispatch("register", { interesse, requestOptions });
        await this.$store.dispatch("interesses/add", interesse);
        //await this.$store.getters["interesses/interesse"];
        
        //  await this.$store.dispatch("seguroincendios/add", dados_enviar);
        this.$notify({
          type: "success",
          message: "Enviado com sucesso",
        });
        this.sendsusses=1;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, Algo deu errado! Consulte a mensagem de erro na tela.",
        });
        this.setApiValidation(error.response.data.errors);
        this.sendsusses=0;
      }
    },
  },
};


</script>
<style></style>
